<template>
    <div>
        <page-header></page-header>

        <!-- Heading and Filename -->
        <div class="header-section">
            <h1>IMPORT TRAINEES</h1>
            <p>{{ fileName }}</p>
        </div>

        <button class="back-button" @click="goBack">Back</button>

        <!-- Import Information -->
        <div :class="{ 'error-info-box': skippedTrainees.length === importedTrainees.length }" class="info-box">
            <!-- Display success or failure message based on the condition -->
            <div v-if="skippedTrainees.length === importedTrainees.length">
                Trainee Import - Unsuccessful.
                <li>Total record(s) : {{ importedTrainees ? importedTrainees.length : 0 }}</li>
                <li>Valid Trainee(s): {{ validTraineeCount }}</li>
                <li style="color:red">Skipped Trainee(s): {{ skippedTrainees ? skippedTrainees.length : 0 }}</li>            
                <p></p>
                Trainee(s) added to Database: {{ validTraineeCount }}
            </div>
            <div v-else>
                Trainees Import - Successful!
                <li>Total record(s) : {{ importedTrainees ? importedTrainees.length : 0 }}</li>
                <li>Valid Trainee(s): {{ validTraineeCount }}</li>
                <li style="color:red">Skipped Trainee(s): {{ skippedTrainees ? skippedTrainees.length : 0 }}</li>            
                <p></p>
                Trainee(s) added to Database: {{ validTraineeCount }}
            </div>
        </div>
        <div class="container-fluid-trainee" style="margin-top: 20px;">
        <h2 style="margin-left: 20px; font-size: 20pt;">Temporary Passwords:</h2>
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th @click="sortBy('first_name')">
            First Name
            <span v-if="sortField === 'first_name'" v-html="getFieldArrow('first_name')"></span>
          </th>
          <th>Last Name</th>
          <th>Email</th>
          <th>UIN</th>
          <th>Temporary Password</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in traineePasswords" :key="item.user_id">
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.email }} </td>
          <td>{{ item.UIN }} </td>
          <td>{{ item.temporary_password }} </td>
        </tr>
        <tr v-if="traineePasswords.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Trainees</td>
        </tr>
      </tbody>
    </table>
    </div>

        <div class="skippedTraineesDisplay">
            <div v-if="skippedTrainees.length">
                <div v-for="item in skippedTrainees" :key="item.email ? item.email : Math.random()">
                    <!-- Display reason for skipped trainee -->
                    <ul v-if="item">
                        <li style="font-weight:bold; white-space: pre-line;">
                            Skipped Trainee - {{ item.email }}: {{ item.message }}
                        </li>
                    </ul>
                    <i v-if="item" style="color:grey; margin-left:20px;">Provided fields</i>
                    <div v-if="item" class="trainee-list">
                        <ol>
                            <li><strong>First Name: &nbsp;</strong> {{ item.first_name }}</li>
                            <li><strong>Last Name: &nbsp;</strong> {{ item.last_name }}</li>
                            <li><strong>Email: &nbsp;</strong> {{ item.email }}</li>
                            <li><strong>Passport Number: &nbsp;</strong> {{ item.passportNo }}</li>
                            <li><strong>Category: &nbsp;</strong> {{ item.category }}</li>
                            <li><strong>Date of Birth: &nbsp;</strong> {{ item.date_of_birth }}</li>
                            <li><strong>Place of Birth: &nbsp;</strong> {{ item.place_of_birth }}</li>
                            <li><strong>UIN: &nbsp;</strong>{{ item.UIN }}</li>
                            <li><strong>Project(s): &nbsp;</strong>{{ item.projects }}</li>
                            <!-- <li><strong>Passport Photo: &nbsp;</strong> {{ item.passportPhoto }}</li>
                            <li><strong>Profile Photo: &nbsp;</strong> {{ item.profilePhoto }}</li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from '@/components/Examiner/Header.vue';

export default {
    name: 'TraineeImportSuccessful',
    data() {
        return {
            entireFileRows: [],
            previewRows: [],
            traineePasswords: [],
        };
    },
    components: {
        pageHeader,
    },
    computed: {
        // Use Vuex to get required data for this component
        skippedTrainees() {
            console.log(this.$store.state.skippedTrainees);
            return this.$store.state.skippedTrainees;
        },
        importedTrainees() {
            return this.$store.state.importedTrainees;
        },
        validTraineeCount() {
            return this.importedTrainees.length - this.skippedTrainees.length;
        },
        groupedSkippedTrainees() {
            if (Array.isArray(this.skippedTrainees)) {
                return this.skippedTrainees.reduce((acc, trainee) => {
                    (acc[trainee.email] = acc[trainee.email] || []).push(trainee);
                    return acc;
                }, {});
            } else {
                return {};  // or any default value you want
            }
        },
        fileName() {
            return this.$store.state.fileName;
        }
    },
    methods: {
        goBack() {
      this.$router.push({ name: 'ImportTrainees' });
    },
        // ... similar methods to importIssues.vue ...
        getTraineeByEmail(email) {
            return this.skippedTrainees.find(trainee => trainee.email === email);
        }
    },
    mounted() {
        console.log("Skipped Trainees:", this.$store.state.skippedTrainees);
        console.log('passwords')
        console.log(this.$store.state.traineePasswords);
        this.traineePasswords = this.$store.state.traineePasswords
    }
}
</script>

<style scoped>
.header-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: bottom;
}

.header-section p {
    margin-right: 60%;
    margin-top: 15px;
    color: grey;
    font-size: 25px;
}

.header-section h1 {
    min-width: 300px;
    font-size: 25px;
}

.info-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 0px 20px;
    color: #137e2a;
    font-weight: bold;
    background-color: #bfeec7;
    width: 60%;
    height: 180px;
    text-align: left;
}

.error-info-box {
    border: 1px solid #a31616;
    padding: 20px;
    margin: 0px 20px;
    color: #550000;
    font-weight: bold;
    background-color: rgba(255, 109, 109, 0.555);
    width: 60%;
    height: 180px;
    text-align: left;
}


.skippedTraineesDisplay {
    text-align: left;
    margin-top: 10px;
    margin-left: 1%;
    background-color: rgb(250, 248, 248);
    width: 98%;
}

.trainee-list {
    background-color: #f0e7e7;
}

.back-button {
  position: absolute;
  /* Adjust the top position as needed */
  right: 60px;
  /* Adjust the right position as needed */
  width: 100px;
  border-radius: 5px;
  background-color: #c1e0bf;
  font-size: 14px;
  border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
  background-color: #abc7a9;
  cursor: pointer;
}
</style>