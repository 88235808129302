<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />

    <div class="filter-container">
      <label for="productFilter">Product:</label>
      <select v-model="productFilter" id="productFilter" class="filter-select">
        <option value=""></option>
        <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}
        </option>
      </select>
      <p v-if="productFilter === ''">Please select a product.</p>
    </div>

    <h1>Reports</h1>

    <button class="btn btn-success rounded" id="trainee-button" @click="goToTraineeReport">Create Trainee Report</button>

    <div class="pagination-button-container" v-if="productFilter !== ''">
      <div class="pagination-container">
        <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
        
      </div>
      <div class="pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ this.tasks.length }} tasks
      </div>
    </div>

    <div class="container-fluid-report" v-if="productFilter !== ''">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th style="width:2%" @click="sortBy('product_id')">
              Task ID
              <span v-if="sortField === 'product_id'" v-html="getFieldArrow()"></span>
            </th>
            <th style="width:2%" @click="sortBy('name')">
              Task No
              <span v-if="sortField === 'name'" v-html="getFieldArrow('name')"></span>
            </th>
            <!--<th style="width:2%">Section ID</th>-->            
            <th style="width:5%">Reference</th>            
            <th style="width:2%">Scope</th>
            <th style="width:2%">Category</th>            
            <th style="width:2%">Manob</th>            
            <th style="width:2%">Est. Time</th>
            <th style="width:2%">Chapter No</th>
            <!--<th style="width:2%">Level</th>-->
            <th class="wider-column">Description</th>
            <!-- <th class="skinny-column">Select</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in this.tasks" :key="item.task_id">
            <td>{{ item.task_id }} </td>
            <td>{{ item.task_no }}</td>
            <td>{{ item.reference }}</td>
            <td>{{ item.scope }} </td>
            <td>{{ item.category }}</td>
            <td>{{ item.manob }} </td>
            <td>{{ item.est_time }} </td>
            <td>{{ item.chapt_no}}</td>
            <td>{{ item.description }} </td>
            <!-- <td>
    <input type="radio" name="selectedProduct" v-model="selectedProductId" :value="item.product_id">
  </td> -->
          </tr>
          <tr v-if="this.tasks.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Tasks</td>
        </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-primary rounded" id="addproductButton" @click="createPDFReport" v-if="productFilter !== ''">Create PDF</button>
  </div>
</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Pagination from '../TablePagination.vue';
import ReportPDF from './ReportPDF.vue';

export default {
  name: 'ViewReports',

  components: {
    Pagination,
    Header,
  },

  data() {
    return {
      products: [],
      projects: [],
      tasks: [],
      sortField: 'product_id',
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      productFilter: '',
      selectedProductId: '',
      joinedName: '',
      showSignature: false,
    };
  },


  watch: {
    productFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.calculateTotalPages();
        localStorage.setItem('product_id', newValue);
        this.fetchTasksForSelectedProduct(newValue);
      }
    },

  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/products', { headers });
      this.products = result.data;

      let projectResult = await axios.get('/api/projects', { headers });
      this.projects = projectResult.data;

      this.totalPages = Math.ceil(this.trainees.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },
  ref: {
    ReportPDF: ReportPDF
  },
  methods: {
    async fetchTasksForSelectedProduct(product_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        let taskResult = await axios.get('/api/tasks', { headers });
        this.tasks = taskResult.data.filter(task => task.prod_id === product_id);

        console.log(this.tasks);

        this.totalPages = Math.ceil(this.tasks.length / this.perPage);

        const selectedProduct = this.products.find(product => product.product_id === product_id);
        this.selectedProductName = selectedProduct ? selectedProduct.name : '';

        const user_id = localStorage.getItem('user_id');

        const userResult = await axios.get(`/api/users/${user_id}`, { headers });

        const userFullName = userResult.data.first_name + ' ' + userResult.data.last_name;
        this.joinedName = userFullName;

        // Generate PDF content after fetching tasks
      } catch (error) {
        console.error('An error occurred while fetching tasks:', error);
      }
    },
    async createPDFReport() {
      this.generateTaskPDF();
    },
    getProjectCount(productId) {
      return this.projects.filter(project => project.prod_id === productId).length;
    },
    getTaskCount(productId) {
      return this.tasks.filter(task => task.prod_id === productId).length;
    },
    getFieldArrow(field = 'product_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    generateTaskPDF() {
      // Create a new instance of jsPDF
      const pdf = new jsPDF();

      if (!this.tasks || this.tasks.length === 0) {
    console.error('Tasks not available');
    return;
  }

      // Add the "Tasks for Product:" heading to the PDF content
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(12); // Adjust the font size if needed
      pdf.text(`Product: ${this.selectedProductName}`, 10, 15);

      // Add a horizontal line underneath the title
      pdf.line(10, 20, 200, 20);

      pdf.text(`Tasks:`, 10, 35);
      pdf.setFont('helvetica', 'normal'); // Reset the font weight to normal
      const imgData = require('./astech.png');
      const imgWidth = 22; // Adjust the width of the image
      const imgHeight = 10; // Adjust the height of the image
      const imgX = pdf.internal.pageSize.width - imgWidth - 10; // Adjust the X position
      const imgY = 7.5; // Adjust the Y position
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);

      // Define the table columns and rows
      //const columns = ['Task ID', 'Task No', 'Sect ID', 'Chapt. No', 'TType', 'Manob', 'Level', 'Description'];
      const columns = ['Task ID', 'Task No', 'Reference', 'Scope', 'Category', 'M/O', 'Est. Time', 'Chapter No.', 'Description'];
      const data = this.tasks.map(item => [
        item.task_id,
        item.task_no,
        item.reference,
        item.scope,
        item.category,
        item.manob,
        item.est_time,
        item.chapt_no,
        item.description,
      ]);

      // AutoTable options
      const options = {
        startY: 40,
        margin: { horizontal: 10 },
        styles: {
          lineWidth: 0.1, // Set the border line width
          lineColor: [169, 169, 169], // Set the border color (black)
        },
      };

      // Add the table to the PDF document
      pdf.autoTable(columns, data, options);


      pdf.setFontSize(11);
      pdf.text(`Signature:`, 38, 280)

pdf.setDrawColor(0, 0, 255);
pdf.setLineWidth(0.1);

  // Draw clickable area as a rectangle
  const clickableArea = {
    x: 60,
    y: 258,
    width: 100,
    height: 20,
  };
  pdf.rect(clickableArea.x, clickableArea.y, clickableArea.width, clickableArea.height);

  // Add the click event to the rectangle
  pdf.link(
    clickableArea.x,
    clickableArea.y,
    clickableArea.width,
    clickableArea.height,
  );

pdf.setDrawColor(0, 0, 0);
pdf.setLineWidth(0.1);
      // Add a horizontal line 
      pdf.line(60, 280, 160, 280);


      // Add a horizontal line 
      pdf.line(10, 285, 200, 285);

      // Calculate the total number of pages after rendering the table
      const totalPages = pdf.internal.getNumberOfPages();
      // Add the footer to each page
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        const pageWidth = pdf.internal.pageSize.width;

        pdf.text(`Author: ${this.joinedName}`, 10, 290);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
        // Calculate the width of the "Printed by" text and the formatted date
        const text = `Printed by: ${this.joinedName} on ${formattedDate}`;
        const textWidth = (pdf.getStringUnitWidth(text) * 8) / 3 ;

        // Calculate the X position to center the text
        const x = (pageWidth - textWidth) / 2;

        pdf.text(text, x, 290);

        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.width - 25, 290); // Adjust the coordinates as needed
      }


      const blob = pdf.output('blob');
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    goToTraineeReport() {
      this.$router.push({ name: 'TraineeReports' });
    },
    onPageChange(page) {
      this.currentPage = page;

      this.calculateTotalPages();
    },
    calculateTotalPages() {
      this.totalPages = Math.ceil(this.tasks.length / this.perPage);
    },
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.tasks.length ? end : this.tasks.length;
    },
  },

}
</script>
  
  
<style scoped>
span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-report {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

button#trainee-button {
  padding: 10px 20px;
  font-size: 16px;
  width: 200px;
  height: 40px;
  margin-bottom: 40px;
  border: 1px solid rgb(17, 80, 20);
  background: #2c752c;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  position: absolute;
  right: 2.5%;
  top: 150px;
}

button#trainee-button:hover {
  background-color: #184218;
  transition: transform 0.2s, box-shadow 0.2s;
}


button.btn#addproductButton {
  width: 200px;
  height: 40px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addproductButton:hover {
  background-color: #0e4791;
  transition: transform 0.2s, box-shadow 0.2s;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}



h1 {
  padding-top: 30px;
  margin-bottom: 80px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20px 0;
  position: absolute;
  width: 50%;
  top: 100px;
  left: 25%;
  padding: 30px;
  padding-bottom: 10px;
  border: 1px solid grey;
  border-radius: 5px;
}

label {
  padding: 5px;
  font-size: 16px;

}



.filter-select#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 80%;
}


.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
}

.data-table-trainee th, .data-table-trainee td {
  text-align: left; /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}


.pagination-container {
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}


.skinny-column {
  width: 10%;
}


.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
  